// components
import SaleTypeColumnMenu from '../../SalesListViewTable/SaleTypeColumnMenu';
import CurrencyCell from '@/components/kendoCells/CurrencyCell';
// utils
import { KendoColumnDef, newColumnDef } from '@/utils/tableLayout/utils';
// interfaces
import { IApplicationsDb } from './interfaces';

const buildDetailRoute: (dataItem: IApplicationsDb) => string = ({ appRecId }) => {
  return `${appRecId}/`;
};

export const applicationsTableColumns: KendoColumnDef<keyof IApplicationsDb>[] = [
  newColumnDef('appDays', 'Days', 80, 'number'),
  newColumnDef('bNames', 'Name', 200, 'link', true, true, buildDetailRoute),
  newColumnDef('appStatus', 'Status', 80),
  newColumnDef('depositDown', 'Deposit', 100, CurrencyCell),
  newColumnDef('stockNum', 'Stk. #', 80),
  newColumnDef('vehYmm', 'Year/Make/Model', 250),
  { field: 'saleType', title: 'Type', width: 110, columnMenu: SaleTypeColumnMenu },
  newColumnDef('upType', 'Up Type', 120),
  newColumnDef('lastContactTime', 'Last Contact', 'auto', 'date'),
  newColumnDef('lastContactType', 'Contact Type', 100),
];
